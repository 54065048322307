<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 15px;")
      div.d-flex.flex-column.justify-start(style="width: 240px;")
        div.fill-height
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") CÓMO SE CALCULA

          div.fill-height.flex-fill.d-flex.flex-column.justify-center
            p(style="font-size: 14px; margin-bottom: 2em;") #[strong Paso 1]- Se define una cesta de productos comodities de la marca y que tengan coincidencia con la competencia
            p(style="font-size: 14px; margin-bottom: 0.8em;") #[strong Paso 2]- Se suman los PVP de todos los productos  y se divide  por el número de productos consiguiendo la media aritmética que es el IPB

      div(style="width: 100%; display: flex; align-items: flex-end;")
        Card_2_SVG_1


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 15px;")
      div.d-flex.flex-column.justify-start(style="width: 240px;")
        div.fill-height
          p(style="color: #F93549; font-weight: 600; margin-bottom: 0.8em;") HOW IT IS CALCULATED:

          div.fill-height.flex-fill.d-flex.flex-column.justify-center
            p(style="font-size: 14px; margin-bottom: 2em;") #[strong Step 1]- A basket of commodity products of the brand is defined, and that match the competition.
            p(style="font-size: 14px; margin-bottom: 0.8em;") #[strong Step 2]- The PVP of all the products is added and divided by the number of products, obtaining the arithmetic average is the CPI

      div(style="width: 100%; display: flex; align-items: flex-end;")
        Card_2_SVG_1_en

</template>

<script>
import Card_2_SVG_1 from "./assets/Card_2_SVG_1";
import Card_2_SVG_1_en from "./assets/Card_2_SVG_1_en";

export default {
  name: "Card_2",
  components: {Card_2_SVG_1, Card_2_SVG_1_en}
}
</script>

<style scoped>

</style>
